<template>
<div class="evaluation mt-5 cart-Page">
    <div class="topboxorder">
        <div class="current-orders py-4">
            <div class="icons d-flex justify-content-center">
                <b-icon icon="circle-fill" class="orange circle-icon h4"></b-icon>
                <b-icon icon="dash" class="orange circle-icon"></b-icon>
                <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
                <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
                <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
                <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
                <b-icon icon="dash" class="orange circle-icon"></b-icon>
                <b-icon icon="circle-fill" class="pink circle-icon h4"></b-icon>
                <b-icon icon="dash" class="pink circle-icon"></b-icon>
                <b-icon icon="dash-lg" class="pink circle-icon"></b-icon>
                <b-icon icon="dash-lg" class="pink circle-icon"></b-icon>
                <b-icon icon="dash-lg" class="pink circle-icon"></b-icon>
                <b-icon icon="dash-lg" class="pink circle-icon"></b-icon>
                <b-icon icon="dash" class="pink circle-icon"></b-icon>
                <b-icon icon="circle-fill" class="pink circle-icon h4"></b-icon>
                <b-icon icon="dash" class="pink circle-icon"></b-icon>
                <b-icon icon="dash-lg" class="pink circle-icon"></b-icon>
                <b-icon icon="dash-lg" class="pink circle-icon"></b-icon>
                <b-icon icon="dash-lg" class="pink circle-icon"></b-icon>
                <b-icon icon="dash-lg" class="pink circle-icon"></b-icon>
                <b-icon icon="dash" class="pink circle-icon"></b-icon>
                <b-icon icon="circle-fill" class="pink circle-icon h4"></b-icon>
            </div>
            <div class="order-done d-flex justify-content-center pt-3">
                <span class="mx-5"> {{ $t("shopping cart") }} </span>
                <span class="mx-5"> {{ $t("shipping address") }} </span>
                <span class="mx-5"> {{ $t("payment") }} </span>
                <span class="mx-5">{{ $t("Submit a request") }} </span>
            </div>
        </div>
    </div>

    <div class="container items">
        <h5 class="d-inline-block ms-2 mb-3">{{ $t("shopping cart") }}</h5>
        <span>{{ cart.cartItemsList.length }} {{ $t("product") }}</span>
        <div class="shopping-cart d-flex w-100">
            <div class="products mb-0">
                <div class="current-orders d-flex align-items-center" v-for="item of cart.cartItemsList">
                    <img :src="
                item.itemId.colorsList.length > 0 &&
                item.itemId.colorsList[0].colorsImage.length > 0
                  ? $baseUploadURL +
                    item.itemId.colorsList[0].colorsImage[0].name
                  : ''
              " alt="" />
                    <div class="content w-86">
                        <div class="content1 d-flex justify-content-between gap-2">
                            <h5>
                                {{ item.itemId ? $getByLang(item.itemId.name) : "" }}

                                <span v-if="item.packageId">
                                    ({{ $getByLang(item.packageId.name) }})
                                </span>
                            </h5>

                            <svg class="ms-2" width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg" @click="removeItem(item)">
                                <path d="M21.4444 10.1L20.5297 22.1988C20.3248 24.908 18.1044 27 15.4336 27H9.56638C6.89564 27 4.67516 24.908 4.47033 22.1988L3.55556 10.1M24 7.5C20.6804 5.85417 16.7341 4.9 12.5 4.9C8.26589 4.9 4.31956 5.85417 1 7.5M9.94444 4.9V3.6C9.94444 2.16406 11.0886 1 12.5 1C13.9114 1 15.0556 2.16406 15.0556 3.6V4.9M9.94444 12.7V20.5M15.0556 12.7V20.5" stroke="#868696" stroke-width="1.5" stroke-linecap="round" />
                            </svg>
                        </div>
                        <span class="price d-block">{{ item.price }} {{ $t("SAR") }}

                            <del class="price" style="padding: 0px 10px; font-size: 16px; color: #a49d9d" v-if="item.offerId">{{ item.priceBefforOffer }} {{ $t("SAR") }}</del>
                        </span>

                        <div class="quantity d-flex justify-content-between">
                            <div class="quan d-flex align-items-center gap-2">
                                <span>{{ $t("Quantity") }}:</span>
                                <button class="plus-btn d-flex align-items-center justify-content-center m-0">
                                    <b-icon icon="plus-lg" class="plus" @click="updateQ(item._id, item.quantity, 'plus')"></b-icon>
                                </button>
                                <span class="num">{{ $codePadding(item.quantity) }}</span>
                                <button class="dash-btn d-flex align-items-center justify-content-center">
                                    <b-icon icon="dash-lg" class="dash" @click="updateQ(item._id, item.quantity, 'minus')"></b-icon>
                                </button>
                            </div>
                            <div class="total">
                                <span class="num1"> {{ $t("Total") }}:</span>
                                <span class="num2">{{ item.total }} {{ $t("SAR") }}</span>
                            </div>
                        </div>
                        <div class="quantity d-flex justify-content-between">
                            <div class="quan d-flex align-items-center gap-2 mt-4">
                                <span>{{ $t("deliver from") }}:</span>
                                <span>{{ item.itemId && item.itemId.deliverFromTime?item.itemId.deliverFromTime:0 }} {{$t("days")}}</span>
                                <span>{{ $t("deliver to") }}:</span>
                                <span>{{ item.itemId&&item.itemId.deliverToTime?item.itemId.deliverToTime:0 }} {{$t("days")}}</span>

                            </div>

                        </div>
                    </div>
                </div>
                <div>

                    <span>{{ $t("deliver from") }}:</span>
                    <span>{{ itemWithMaxDeliverToTime.itemId? itemWithMaxDeliverToTime.itemId.deliverFromTime:0}} {{$t("days")}} </span>
                    <span> {{ $t("deliver to") }}:</span>
                    <span>{{ itemWithMaxDeliverToTime.itemId? itemWithMaxDeliverToTime.itemId.deliverToTime:0 }} {{$t("days")}} </span>

                </div>
            </div>

            <div class="discount-box">
                <div class="discount">
                    <div class="gifts">
                        <p>{{ $t("Discount coupons and gift cards") }}</p>
                        <div class="btns d-flex justify-content-between">
                            <input type="text" class="sign1" :value="cart.discountId.name" :placeholder="$t('Enter the coupon or card code')" v-if="cart.discountId" disabled />
                            <input v-else type="text" class="sign1" v-model="discountCode" :placeholder="$t('Enter the coupon or card code')" @keyup.enter="checkDescount()" />
                            <button class="sign2" @click="deleteDiscount()" v-if="cart.discountId">
                                {{ $t("cncel") }}
                            </button>
                            <button class="sign2" @click="checkDescount()" v-else>
                                {{ $t("use") }}
                            </button>
                        </div>
                    </div>

                    <div class="orders-summary">
                        {{ $t("Order summary") }}
                        <div class="date d-flex justify-content-between mb-2">
                            {{ $t("Subtotal") }}
                            <span class="price">{{ cart.subTotal }} {{ $t("SAR") }}</span>
                        </div>
                        <div class="date d-flex justify-content-between mb-2" v-if="cart.discountValue > 0">
                            <p>{{ $t("Discount") }}</p>
                            <span class="price">-{{ discountVal.toFixed(2) }} {{ $t("SAR") }}</span>
                        </div>
                        <div class="date d-flex justify-content-between mb-2">
                            <p>{{ $t("Total") }}</p>
                            <span class="price">{{ (cart.subTotal - discountVal).toFixed(2) }} {{ $t("SAR") }}</span>
                        </div>
                    </div>

                    <div class="delivery-costs mt-4">
                        <h6>
                            {{ $t("Delivery costs") }}
                        </h6>
                        <p>
                            {{
                  $t("It will be calculated after adding the delivery address")
                }}
                        </p>
                        <!-- <div class="plus-btn2"> -->
                        <div class="text-center">
                            <router-link to="/shippingAddress">
                                <button class="border-0">
                                    <b-icon icon="plus" class="plus"></b-icon>
                                    {{ $t("Add Address") }}
                                </button>
                            </router-link>
                        </div>
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
export default {
    name: "sendRequest",
    data() {
        return {
            minDeliverFromTime: 0,
            maxDeliverToTime: 0,
            discountVal: 0,
            cart: {
                subTotal: 0,
                total: 0,
                cartItemsList: []
            },
            discountCode: null
        };
    },
    methods: {
        checkDescount() {
            this.$http
                .post("discounts/checkdiscountCode", {
                    discountCode: this.discountCode,
                    cartId: this.cart._id
                })
                .then(
                    (res) => {
                        console.log("discount data", res.data);
                        console.log("this.cart", this.cart);

                        this.getCarts();
                        // location.reload()
                    },
                    (err) => {
                        this.$toast.add({
                            severity: "error",
                            summary: this.$t("Error"),
                            detail: err.response.data.message,
                            life: 3000
                        });
                    }
                );
        },
        deleteDiscount() {
            this.$http.post("cart/deleteDiscount", {}).then((res) => {
                this.getCarts();
                // location.reload()
            });
        },
        getCarts() {
            this.$http.get("cart/withoutReCalculate").then(
                (res) => {
                    if (res.data.returnStatus) {
                        console.log(res.data.data);

                        this.cart = res.data.data;
                        // this.minDeliverFromTime = this.cart.cartItemsList.reduce((min, item) => {
                        //   // الحصول على قيمة deliverFromTime أو تعيينها 0 إذا لم تكن موجودة
                        //   let deliverFromTime = item.itemId.deliverFromTime !== undefined ? item.itemId.deliverFromTime : 0;
                        //   return deliverFromTime < min ? deliverFromTime : min;
                        // }, Infinity); // نبدأ من Infinity لضمان الحصول على أقل قيمة صحيحة  
                        // this.maxDeliverToTime = this.cart.cartItemsList.reduce((max, item) => {
                        //   // الحصول على قيمة deliverToTime أو تعيينها 0 إذا لم تكن موجودة
                        //   let deliverToTime = item.itemId.deliverToTime !== undefined ? item.itemId.deliverToTime : 0;
                        //   return deliverToTime > max ? deliverToTime : max;
                        // }, 0); // نبدأ من 0 كقيمة ابتدائية لضمان الحصول على أكبر قيمة صحيحة

                        this.itemWithMaxDeliverToTime = this.cart.cartItemsList.reduce((maxItem, item) => {
                            let deliverToTime = item.itemId.deliverToTime !== undefined ? item.itemId.deliverToTime : 0;
                            return deliverToTime > (maxItem.itemId.deliverToTime || 0) ? item : maxItem;
                        }, {
                            itemId: {
                                deliverToTime: 0
                            }
                        });

                        console.log("minDeliverFromTime", this.minDeliverFromTime);
                        console.log("maxDeliverToTime", this.itemWithMaxDeliverToTime);

                        if (this.discountVal == 0 && this.cart.discountId != null) {
                            if (this.cart.discountId) {
                                if (this.cart.discountId.discountType.en == "percentage") {
                                    this.discountVal =
                                        res.data.data.subTotal * (this.cart.discountId.value / 100);
                                }
                                if (this.cart.discountId.discountType.en != "percentage") {
                                    this.discountVal =
                                        res.data.data.subTotal - this.cart.discountId.value;
                                }
                            }
                            // location.reload();
                        }
                        if (this.cart.total != this.cart.subTotal) {
                            // location.reload();
                        }
                    } else {
                        this.$router.push("/");
                    }
                },
                (err) => {
                    this.$router.push("/");
                    this.$toast.add({
                        severity: "error",
                        summary: this.$t("Error"),
                        detail: err.response.data.message,
                        life: 3000
                    });
                }
            );
        },
        removeItem(item) {
            this.$http.delete("cartDetails/" + item._id).then((res) => {
                // if (res.data.returnStatus) {
                this.getCarts();
                this.$eventHub.$emit("updateCart", {});
                // }
            });
        },
        updateQ(id, quantity, mark) {
            this.discountVal;

            let quantityModified;
            if (mark == "plus") {
                quantityModified = ++quantity;
            }
            if (mark == "minus") {
                quantityModified = --quantity;
            }

            this.$http
                .put("cartDetails/" + id, {
                    quantity
                })
                .then((res) => {
                    if (this.cart.discountId) {
                        if (this.cart.discountId.discountType.en == "percentage") {
                            this.discountVal =
                                res.data.data.total * (this.cart.discountId.value / 100);
                        }
                        if (this.cart.discountId.discountType.en != "percentage") {
                            this.discountVal =
                                res.data.data.total - this.cart.discountId.value;
                        }
                    }
                    this.getCarts();
                });
        }
    },
    created() {
        this.getCarts();

    }
};
</script>

<style></style>
